<template>
  <section class="m-user-info m-scroll">
    <!-- 头部导航 -->
    <m-header title="Your Information" >
      <span slot="right" @click="onSave" class="header-right">Done</span>
    </m-header>

    <div class="avatar van-hairline--top">
      <van-uploader :after-read="onRead">
        <div class="upload-slot">
          <img :src="user.pic_url == '' ? $store.state.avatar :  user.pic_url" alt="avatar">
          <p>Click to modify profile photo</p>
        </div>
      </van-uploader>
    </div>

    <div class="info">
      <div class="info-cell">
        <div class="label">Name</div>
        <div class="right">
          <input ref="name" type="text" v-model="user.nickname" >
        </div>
        <img  src="@/assets/public/icon_edit@2x.png" alt="Edit">
      </div>

      <div class="info-sex">
        <div class="label">Gender</div>
        <div class="right">
          <van-radio-group v-model="user.sex" direction="horizontal">
            <van-radio name="1" checked-color="#ED2A24" icon-size="16px">Male</van-radio>
            <van-radio name="2" checked-color="#ED2A24" icon-size="16px">Female</van-radio>
            <van-radio name="0" checked-color="#ED2A24" icon-size="16px">Secrecy</van-radio>
          </van-radio-group>
        </div>
      </div>

    </div>


    <div class="main">

      <div @click="changeEmail()"  class="setting-cell">
        <div class="label">
          <span>E-mail</span>
        </div>
        <div class="right">
          <p v-if="user.email != ''" class="bind-item">{{user.email}}</p>
          <p v-else class="unbind-item">Unbound</p>
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </div>

     <div @click="handlePhone"  class="setting-cell van-hairline--bottom">
        <div class="label ">
          <span>Phone</span>
        </div>
        <div class="right">
          <p v-if="user.phone!= ''" class="bind-item">{{user.phone}}</p>
          <p v-else class="unbind-item">Unbound</p>
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </div>

      <div  class="setting-cell" @click="changePassword()">
        <div class="label">
          <span>Password</span>
        </div>
        <div class="right">
          <p class="unbind-item">Modify</p>
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </div>

    </div>

    <div class="main">

      <router-link to  class="setting-cell van-hairline--bottom">
        <div class="label">
          <img class="label-icon" src="@/assets/public/icon_weiin.png" alt="wechat">
          <span>Wechat</span>
        </div>
        <div class="right">
          <p v-if="account.is_wx != 0" class="bind-item">Bind</p>
          <p v-else class="unbind-item">Unbound</p>
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

     <router-link to  class="setting-cell van-hairline--bottom">
        <div class="label ">
          <img class="label-icon" src="@/assets/public/icon_face.png" alt="FB">
          <span>FaceBook</span>
        </div>
        <div class="right">
          <p v-if="account.is_faceid" class="bind-item">Bind</p>
          <p v-else class="unbind-item">Unbound</p>
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to  class="setting-cell">
        <div class="label">
          <img class="label-icon" src="@/assets/public/icon_ins.png" alt="wechat">
          <span>Instagram</span>
        </div>
        <div class="right">
          <p v-if="account.is_instaid" class="bind-item">Bind</p>
          <p v-else class="unbind-item">Unbound</p>
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      

    </div>

    <div class="footer">
      <van-button @click="onSave" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Done</van-button>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'
import {  getAccountInfo, editAccountInfo, userBind } from '@/api/zh/mine.js'
import { upload } from '@/api/public.js'

export default {
  name:'UserInfo',
  components:{ MHeader },
  data(){
    return {
      password: '**********',
      user: { phone: '', nickname: '', sex: '0',  pic_url: '', pic_id: '' },
      account:{
        is_wx: 0,
        is_faceid: 0,
        is_instaid: 0
      },
    }
  },
  methods:{
    // 点击编辑
    handleReadonly(value) {
      this.readonly[value] = false
      this.$refs[value].focus()
    },

    // 获取用户信息
    getData() {
      getAccountInfo().then(res => {
        if(res) {
          this.user = res.data
          this.user.sex = this.user.sex + ''
          localStorage.setItem('setPersonal',JSON.stringify(res.data))
        }
      })
    },

    // 点击保存
    onSave(){
      // 判断是否填写手机号或者邮箱
      if(this.user.phone == '' && this.user.email == '') {
        this.$notify({ type: 'primary', message: 'Please bind mobile phone number or email first' })
        return false
      }

      let params = {
        sex: this.user.sex,
        pic: this.user.pic_id
      }
      if(this.user.nickname == "") {
        this.$notify({ type: 'warning', message: 'Nickname cannot be empty' })
        return false
      }else {
        params.nickname = this.user.nickname
      }

      editAccountInfo(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.$router.go(-1)
        } 
      })
    },

    // 上传插件 读取完成本地文件时
    onRead(file){
      var image = new FormData()
      image.append('filename', 'Avatar')
      image.append(`file`, file.file)
      upload(image).then(res => {
        if(res) {
          this.user.pic_url = res.data.url
          this.user.pic_id  = res.data.pic_id
        }
      })
    },

    // 获取绑定信息
    getBind() {
      userBind().then(res => {
        if(res) {
          this.account = res.data
        }
      })
    },
      handlePhone() {
      if (this.phone != "" || this.email != "") {
        this.$router.push({
          path: "/en/setting/phone",
          query: { phone: this.phone, email: this.email },
        });
      } else {
        this.$notify({ type: "warning", message: "Please bind your phone or email" });
        return false;
      }
    },
    //修改密码
    changePassword() {
      if(this.phone != '' || this.email != '') {
        this.$router.push( {path: '/en/setting/identity', query: { phone: this.user.phone,  email: this.user.email }} )
      }else {
        this.$notify({ type: 'warning', message: 'Please bind your phone or email' })
        return false
      }
    },

    //修改邮箱
    changeEmail() {
      if(this.phone != '' || this.email != '') {
        if (this.email != "") {
          this.$router.push({
            path: "/en/setting/email",
            query: { phone: this.user.phone, email: this.user.email },
          });
        } else {
          this.$router.push({ path: "/en/setting/bindemail" });
        }
      }else {
        this.$notify({ type: 'warning', message: 'Please bind your phone or email' })
        return false
      }
    }
   
  },

  created() {
    this.getData()
    this.getBind()
  }
}
</script>

<style lang="less" scoped>
@import './user.less';
</style>